html,
body,
#root {
  height: 100%;
}

.App {
  height: 100%;
  text-align: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pagination a {
  padding: 5px 10px 8px 10px !important;
  min-width: 0px !important;
}
